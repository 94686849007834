import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Credentials, AccessToken } from '../models/auth';
import { AuthActionsUnion, AuthActionTypes } from '../actions/auth';

export interface State {
  currentUser: AccessToken | null;
  loading: boolean | false;
  complete: boolean | false;
  message: string | null;
}

export const initialState: State = {
  currentUser: null,
  loading: false,
  complete: true,
  message: null,
};

export function reducer(state = initialState, action: AuthActionsUnion): State {
  switch (action.type) {

    case AuthActionTypes.Login: {
      return {
        ...state,
        loading: true,
      };
    }

    case AuthActionTypes.LoginComplete: {
      return {
        ...state,
        currentUser: action.payload,
        message: null,
        loading: false,
        complete: true,
      };
      
    }

    case AuthActionTypes.LoginCompleteError: {
      return {
        ...state,
        loading: false,
        complete: true,
        currentUser: null,
        message: action.payload,
      };
    }

    case AuthActionTypes.Load: {
      if (state.currentUser) {
        return state;
      }

      return {
        message: null,
        loading: false,
        complete: true,
        currentUser: action.payload as AccessToken,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;
export const getComplete = (state: State) => state.complete;
export const getCurrentUser = (state: State) => state.currentUser;
export const getMessage = (state: State) => state.message;
