import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


export const routes: Routes = [

  {
    path: '',
    loadChildren: './kt/kt.module#ktModule',
  },
  {
    path: 'login',
    loadChildren: './login/auth.module#AuthModule',
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRouter {}
