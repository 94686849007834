import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LocalStorageService } from '../../core/local-storage';
import * as fromAuth from '../reducers';
import * as auth from '../actions/auth';

@Injectable()
export class LoginExistsGuard implements CanActivate {

  constructor(private store: Store<fromAuth.State>,
              private service: LocalStorageService,
              private router: Router) {}

  waitForLoginToLoad(): Observable<boolean> {
    return this.store.select(fromAuth.getAuthComplete)
      .pipe(
        filter(loaded => loaded),
        take(1)
      );
  }

  hasInStore(): Observable<boolean> {
    return this.store.select(fromAuth.getCurrentUser)
      .pipe(map(user => !!user))
  }

  hasInStorage(): Observable<boolean> {
    return of(this.service.get('currentAccessToken'))
      .pipe(
        // tap(item => this.store.dispatch(new auth.Load(item))),
        map(item => !!item),
        catchError(() => of(false))
      );
  }

  hasIn(): Observable<boolean> {
    return this.hasInStore()
      .pipe(
        switchMap(inStore => {
          if (inStore)
            return of(inStore);
          return this.hasInStorage();
        })
      )
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.waitForLoginToLoad()
      .pipe(
        switchMap(() => this.hasIn()),
        tap(has => {
          if (!has) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            this.store.dispatch(new auth.LoginCompleteError('Требуется авторизация.'));
          }
        })
      );
  }
}
