import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class LocalStorageService {
  private exp: number = 10*24*60*60*1000;
  private prefixKey: string = 'cerebra-app';
  private storage = localStorage;
  constructor(private router: Router) {
    
  }
  set(name, data) {

    let localData: any = this.storage.getItem(this.prefixKey);
    if (localData) {
      localData = JSON.parse(localData);
    } else {
      localData = {};
    }

    // let exp = data === null ? null : this.updateExpiration();
    localData[name] = {
      data: data,
      // expires: exp
    };
    this.storage.setItem(this.prefixKey, JSON.stringify(localData));
  }

  get(name) {
    let data = JSON.parse(this.storage.getItem(this.prefixKey));
    if (!data || !name || !data[name]) {
      return undefined;
    }
    // if (name == 'currentAccessToken') {
    //   if (data[name].expires < Date.now()) {
    //     this.set(name, null);
    //     this.router.navigate(['login'])
    //     return undefined;
    //   }
    // } else {
    //   this.set(name, data[name].data);
    // }
    return data[name].data;
  }

  delete(name) {
    this.set(name, null);
  }

  private updateExpiration() {
    return Date.now() + (this.exp);
  }
}
