import { Action } from '@ngrx/store';
import { Credentials, AccessToken } from '../models/auth';

export enum AuthActionTypes {
  Load = '[Auth] Login',
  Login = '[Auth] Login',
  LoginComplete = '[Auth] Login Complete',
  LoginCompleteError = '[Auth] Login Complete Error',
  GetUserInfo = '[Auth] GetUserInfo',
  GetUserInfoComplete = '[Auth] GetUserInfo Complete',
}

export class Load implements Action {
  readonly type = AuthActionTypes.Load;

  constructor(public payload: AccessToken) {}
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: Credentials) {}
}

export class LoginComplete implements Action {
  readonly type = AuthActionTypes.LoginComplete;

  constructor(public payload: AccessToken) {}
}

export class LoginCompleteError implements Action {
  readonly type = AuthActionTypes.LoginCompleteError;

  constructor(public payload: string) {}
}

export class GetUserInfo implements Action {
  readonly type = AuthActionTypes.GetUserInfo;

  constructor(public payload: string) {}
}
export class GetUserInfoComplete implements Action {
  readonly type = AuthActionTypes.GetUserInfoComplete;

  constructor(public payload: any) {}
}

export type AuthActionsUnion =
  | Load
  | Login
  | LoginComplete
  | LoginCompleteError
  | GetUserInfo
  | GetUserInfoComplete
  ;
