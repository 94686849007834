import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap,
} from '@ngrx/store';

import * as fromAuth from './auth';

export interface State {
  auth: fromAuth.State;
}

export const reducers: ActionReducerMap<State> = {
  auth: fromAuth.reducer,
};

export const getAppState = createFeatureSelector<State>('kt-auth');

// ========= Auth
export const getAuthState = createSelector(getAppState, state => state.auth);

export const getAuthLoading = createSelector(getAuthState, fromAuth.getLoading);
export const getAuthComplete = createSelector(getAuthState, fromAuth.getComplete);
export const getCurrentUser = createSelector(getAuthState, fromAuth.getCurrentUser);
export const getAuthMessage = createSelector(getAuthState, fromAuth.getMessage);
